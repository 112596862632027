import plugin from 'tailwindcss/plugin'

const tailwindConfig = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './rfxv1/**/*.{js,ts,jsx,tsx}',
    './pro/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    fontFamily: {
      display: ['var(--font-body)'],
      body: ['var(--font-body)'],
      rewards: ['var(--font-body)'],
      flexo: ['var(--font-body)'],
    },
    extend: {
      animation: {
        marquee: 'marquee 25s linear infinite',
        marquee2: 'marquee2 25s linear infinite',
      },
      boxShadow: {
        bottomBar: '0px -4px 8px -1px rgba(0,0,0,0.2)',
        'primary-color':
          '0px 0px 14.6px 0px #696EFF, 0px 0px 133.266px 0px #696EFF',
      },
      colors: {
        black: '#000',
        'recentTrade-toggle': '#B8B8B8',

        heatmap: {
          first: '#3D0B51',
          second: '#53A884',
          third: '#F2A540',
        },

        patek: {
          brand: {
            primary: '#696EFF',
            icon: '#A5A8FF',
          },
          greyscale: {
            'text-primary': '#FFFFFF',
            'text-secondary': '#FFFFFFB2',
            'grey-secondary': '#909096',
            'grey-territory': '#56565A',
            'grey-darker': '#212121',
          },
          border: {
            primary: '#292929',
            secondary: '#FFFFFF0D',
          },
          success: {
            primary: '#6ED5A3',
            secondary: '#141F1D',
          },
          error: {
            primary: '#D56E6E',
            secondary: '#311919',
          },
          warning: {
            primary: '#FF9C24',
          },
          background: {
            'surface-primary': '#111113',
            'surface-secondary': '#202020',
            'surface-highlight': '#2E2E2E',
            'surface-grey': '#404040',
            'surface-modal': '#09090A',
            'bkg-6': '#212121',
          },
          button: {
            DEFAULT: '#FFFFFF',
            hover: '#FFFFFF',
          },
        },

        stargazer: {
          brand: {
            primary: '#696EFF',
            icon: '#A5A8FF',
          },
          greyscale: {
            'text-primary': '#FFFFFF',
            'text-secondary': '#FFFFFFB2',
            'grey-secondary': '#909096',
            'grey-territory': '#56565A',
          },
          border: {
            primary: '#292929',
            secondary: '#FFFFFF0D',
          },
          success: {
            primary: '#00E100',
            secondary: '#141F1D',
          },
          error: {
            primary: '#EF323F',
            secondary: '#21080D',
          },
          warning: {
            primary: '#E78129',
          },
          background: {
            'surface-primary': '#111113',
            'surface-secondary': '#262527',
            'surface-highlight': '#1A191B',
            'surface-grey': '#404040',
            'surface-modal': '#09090A',
          },
          button: {
            DEFAULT: '#FFFFFF',
            hover: '#FFFFFF',
          },
        },

        'th-bkg-1': 'var(--bkg-1)',
        'th-bkg-2': 'var(--bkg-2)',
        'th-bkg-3': 'var(--bkg-3)',
        'th-bkg-4': 'var(--bkg-4)',
        'th-bkg-5': 'var(--bkg-5)',
        'th-bkg-6': 'var(--bkg-6)',
        'th-fgd-1': 'var(--fgd-1)',
        'th-fgd-2': 'var(--fgd-2)',
        'th-fgd-3': 'var(--fgd-3)',
        'th-fgd-4': 'var(--fgd-4)',
        'th-fgd-5': 'var(--fgd-5)',
        'th-active': 'var(--active)',
        'th-active-icon': 'var(--active-icon)',
        'th-error': 'var(--error)',
        'th-error-secondary': 'var(--error-secondary)',
        'th-success': 'var(--success)',
        'th-success-secondary': 'var(--success-secondary)',
        'th-warning': 'var(--warning)',
        'th-down': 'var(--down)',
        'th-down-dark': 'var(--down-dark)',
        'th-up': 'var(--up)',
        'th-up-dark': 'var(--up-dark)',
        'th-button': 'var(--button)',
        'th-button-hover': 'var(--button-hover)',
        'th-input-bkg': 'var(--input-bkg)',
        'th-input-border': 'var(--input-border)',
        'th-input-border-secondary': 'var(--input-border-secondary)',
        'th-input-border-hover': 'var(--input-border-hover)',
      },
      backgroundImage: {
        'heatmap-bullish': `linear-gradient(180deg, #6ED5A3 0%, rgba(52, 103, 76, 0.46) 53.72%, rgba(1, 7, 0, 0.00) 100%)`,
        'heatmap-slightly-bullish': `linear-gradient(180deg, rgba(110, 213, 163, 0.20) 0%, rgba(52, 103, 76, 0.09) 53.72%, rgba(1, 7, 0, 0.00) 100%)`,
        'heatmap-slightly-bearish': `linear-gradient(180deg, rgba(213, 110, 110, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%)`,
        'heatmap-bearish': `linear-gradient(180deg, #D56E6E 0%, rgba(0, 0, 0, 0.00) 100%)`,
      },
      fontSize: {
        xxs: '.65rem',
      },
      keyframes: {
        marquee: {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: 'translateX(-100%)' },
        },
        marquee2: {
          '0%': { transform: 'translateX(100%)' },
          '100%': { transform: 'translateX(0%)' },
        },
        shimmer: {
          '100%': {
            transform: 'translateX(100%)',
          },
        },
      },
      screens: {
        '1.5xl': '1408px',
      },
    },
  },
  plugins: [
    plugin(function ({ addComponents }) {
      addComponents({
        '.modal-glass': {
          /* Background/Modal/Glass */
          background:
            'linear-gradient(180deg, rgba(98, 106, 136, 0.1) 0%, rgba(46, 49, 73, 0.1) 100%)',
        },
        '.graph-error': {
          /* Red/Graph/Error */
          background:
            'linear-gradient(180deg, rgba(213, 110, 110, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%)',
        },
        '.graph-success': {
          /* Green/Graph/Success */
          background:
            'linear-gradient(180deg, rgba(110, 213, 163, 0.20) 0%, rgba(52, 103, 76, 0.09) 53.72%, rgba(1, 7, 0, 0.00) 100%)',
        },
        '.gradient-logo': {
          /* Main/Gradient/Logo */
          background:
            'linear-gradient(214deg, #AEB1FD 10.94%, #AEB1FD 10.95%, #0E14C8 114.39%)',
        },
      })
    }),
  ],
}

export default tailwindConfig
