import { getDefaultConfig, WalletList } from '@rainbow-me/rainbowkit'
import {
  coinbaseWallet,
  coreWallet,
  metaMaskWallet,
  okxWallet,
  trustWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { createPublicClient, http } from 'viem'
import {
  arbitrum,
  base,
  linea,
  mainnet,
  optimism,
  polygon,
  zksync,
} from 'viem/chains'

const WALLET_CONNECT_PROJECT_ID = '6572e4a7d8957027454168f031ce3c30'
const APP_NAME = 'RFX'

export const publicClient = (chainId: any) =>
  createPublicClient({
    chain: chainId,
    transport: http(''),
  })

const popularWalletList: WalletList = [
  {
    // Group name with standard name is localized by rainbow kit
    groupName: 'Popular',
    wallets: [metaMaskWallet, walletConnectWallet],
  },
]

const othersWalletList: WalletList = [
  {
    groupName: 'Others',
    wallets: [coinbaseWallet, trustWallet, coreWallet, okxWallet],
  },
]

export const rainbowKitConfig = getDefaultConfig({
  appName: APP_NAME,
  projectId: WALLET_CONNECT_PROJECT_ID,
  chains: [arbitrum, base, linea, mainnet, optimism, polygon, zksync],
  transports: {
    [zksync.id]: http(),
    [arbitrum.id]: http(),
    [base.id]: http(),
    [linea.id]: http(),
    [mainnet.id]: http(),
    [optimism.id]: http(),
    [polygon.id]: http(),
  },
  wallets: [...popularWalletList, ...othersWalletList],
})
